<template>
  <v-container>
    <AlertSnackbar :disp="answer.status >= 3" color="accent" message="回答は送信済みです"></AlertSnackbar>
    <v-row>
      <v-col>
        <h2>{{officeData.companyName}}様 {{officeData.officeName}} 月次報告データ</h2>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-btn 
          class="btn_font primary mr-2"
          width="150"
          v-on:click="showImport()"
          :disabled="loading || ((attribute == 0) && (answer.status >= 3))"
        >回答票インポート</v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col class="pt-1">
        <v-tabs class="" :slider-size=0>
          <v-tab href="#tab-no8" class="tab-width-200">8号様式<v-icon v-if="!this.formNo8Valid" :small="true" color="warning">mdi-alert</v-icon></v-tab>
          <v-tab href="#tab-no9" class="tab-width-200">9号様式<v-icon v-if="!this.formNo9Valid" :small="true" color="warning">mdi-alert</v-icon></v-tab>
          <v-tab href="#tab-imported" class="tab-width-200">輸入物資<v-icon v-if="!this.formImportedVald" :small="true" color="warning">mdi-alert</v-icon></v-tab>

          <!-- 8号 -->
          <v-tab-item value="tab-no8" class="tab-item" :transition="false" eager>
            <v-form ref="formNo8" v-model="validNo8" lazy-validation>
              <v-row>
                <v-col>
                  <div>第八号様式（第２４条関係）</div>
                  <h2>月末倉庫使用状況報告書</h2>
                </v-col>
              </v-row>
              <v-row class="justify-space-between">
                <v-col cols="2" sm="2" md="2" lg="2" class="pb-0">
                  <DatePicker
                    v-model="answer.targetMonth" 
                    format="YYYY年M月" 
                    selectType="month" 
                    suffix="末現在" 
                    single-line
                    :clearable="false"
                    readonly
                    propClass="centered-input"
                    :loading="loading"
                    :propInert="true"
                  ></DatePicker>
                </v-col>
                <v-col cols="5" sm="5" md="5" lg="5" class="pb-0">
                  <v-text-field 
                    prefix="氏名又は名称：" 
                    v-model="answer.companyName"
                    dense 
                    :loading="loading"
                    :readonly="attribute == 0 && answer.status >= 3"
                    :rules="[requiredIf_ns(() => saveRegist)]"
                    :maxlength="100"
                    :counter="100"
                    background-color="input"
                    tabindex="-1"
                  ></v-text-field></v-col>
              </v-row>
              <v-row class="justify-space-between">
                <v-col cols="2" sm="2" md="2" lg="2" class="pt-0">
                  <v-text-field
                    :value="officeData.prefName" 
                    single-line 
                    readonly 
                    dense 
                    class="centered-input" 
                    :loading="loading"
                    inert
                  ></v-text-field>
                </v-col>
                <v-col cols="5" sm="5" md="5" lg="5" class="pt-0">
                  <v-text-field 
                    prefix="営業所の名称：" 
                    v-model="answer.officeName" 
                    dense 
                    :loading="loading"
                    :readonly="attribute == 0 && answer.status >= 3"
                    :rules="[]"
                    :maxlength="100"
                    :counter="100"
                    background-color="input"
                    tabindex="-1"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-data-table :hide-default-header="true" :hide-default-footer="true" class="no-hover-effect table-border table-8">
                <template v-slot:body="{}">
                  <thead>
                    <tr>
                      <th class="text-center" rowspan="2"></th>
                      <th class="text-center" rowspan="2">所管容積</th>
                      <th class="text-center" colspan="4">使用状況</th>
                      <th class="text-center" colspan="2">備考</th>
                    </tr>
                    <tr>
                      <th class="text-center" colspan="2">受寄物在貨容積</th>
                      <th class="text-center">自家貨物在貨容積</th>
                      <th class="text-center">空容積</th>
                      <th class="text-center">製氷</th>
                      <th class="text-center">凍結</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th rowspan="2">冷蔵倉庫</th>
                      <td rowspan="2" class="greenBack">
                        <NumberTextField 
                          v-model="answer8.volumeTotal" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[requiredIf_ns(() => saveRegist), maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㎥"
                          tabindex="1"
                        ></NumberTextField>
                      </td>
                      <td colspan="2">
                        <NumberTextField 
                          v-model="answer8VolumeReceiptCalc" 
                          dense
                          hide-details 
                          readonly 
                          :loading="loading"
                          suffix="㎥"
                          inert
                        ></NumberTextField>
                      </td>
                      <td rowspan="2" class="greenBack">
                        <NumberTextField 
                          v-model="answer8.volumePrivate" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㎥"
                          tabindex="3"
                        ></NumberTextField>
                      </td>
                      <td rowspan="2">
                        <NumberTextField 
                          v-model="answer8VolumeAvailableCalc" 
                          dense
                          readonly 
                          :loading="loading"
                          suffix="㎥"
                          inert
                          :rules="[minValLocaleJP(0)]"
                        ></NumberTextField>
                      </td>
                      <td rowspan="2" class="greenBack">
                        <NumberTextField 
                          v-model="answer8.weightIce" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㌧"
                          tabindex="4"
                        ></NumberTextField>
                      </td>
                      <td rowspan="2" class="greenBack">
                        <NumberTextField 
                          v-model="answer8.weightFrozen" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㌧"
                          tabindex="5"
                        ></NumberTextField>
                      </td>
                    </tr>
                    <tr>
                      <td>内容積建</td>
                      <td class="greenBack">
                        <NumberTextField 
                          v-model="answer8.volumeMeasure" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㎥"
                          tabindex="2"
                        ></NumberTextField>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>

              <v-row class="justify-end">
                <v-col cols="4" sm="4" md="4" lg="4" class="pt-0">
                  <v-text-field 
                    prefix="担当者名：" 
                    v-model="answer.responderName8" 
                    single-line 
                    dense 
                    :loading="loading"
                    :readonly="attribute == 0 && answer.status >= 3"
                    :rules="[requiredIf_ns(() => saveRegist)]"
                    :maxlength="50"
                    :counter="50"
                    background-color="input"
                    tabindex="-1"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <div>(注意)</div>
                  <ul>
                    <li>1.営業所ごとに作成すること。</li>
                    <li>2.容積は有効容積を記載すること。</li>
                    <li>3.冷蔵倉庫の「受寄物在貨容積」の欄の下段は、容積健保管に使用している容積を内数として記載すること。</li>
                  </ul>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <!-- 9号 -->
          <v-tab-item value="tab-no9" class="tab-item" :transition="false" eager>
            <v-form ref="formNo9" v-model="validNo9" lazy-validation>
              <v-row>
                <v-col>
                  <div>第九号様式（第２４条関係）</div>
                  <h2>受寄物入出庫高及び保管残高報告書</h2>
                </v-col>
              </v-row>

              <v-row class="justify-space-between">
                <v-col cols="2" sm="2" md="2" lg="2" class="pb-0">
                  <DatePicker 
                    label="調査月" 
                    v-model="answer.targetMonth" 
                    format="YYYY年M月" 
                    selectType="month" 
                    suffix="末現在" 
                    single-line 
                    :clearable="false" 
                    readonly
                    propClass="centered-input"
                    :loading="loading"
                  ></DatePicker>
                </v-col>
                <v-col cols="5" sm="5" md="5" lg="5" class="pb-0">
                  <v-text-field 
                    prefix="氏名又は名称：" 
                    v-model="answer.companyName" 
                    readonly 
                    dense 
                    :loading="loading"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-space-between">
                <v-col cols="2" sm="2" md="2" lg="2" class="pt-0">
                  <v-text-field 
                    :value="officeData.prefName" 
                    single-line 
                    readonly 
                    dense 
                    class="centered-input" 
                    :loading="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" sm="5" md="5" lg="5" class="pt-0">
                  <v-text-field 
                    prefix="営業所の名称：" 
                    v-model="answer.officeName" 
                    readonly 
                    dense 
                    :loading="loading"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-data-table :hide-default-header="true" :hide-default-footer="true" class="no-hover-effect table-border table-9">
                <template v-slot:body="{}">
                  <thead>
                    <tr>
                      <th colspan="2"></th>
                      <th>前月末保管残高</th>
                      <th>当月中入庫高</th>
                      <th>当月中出庫高</th>
                      <th>当月末保管残高</th>
                      <th>備考</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- 合計以外 -->
                    <tr v-for="(data, index) in answer9" :key="index">
                      <th width="70" class="center">{{ index + 1 }}</th>
                      <th width="200">{{ getCityDataNo9Item(data.type).text }}</th>
                      <td :class="(attribute != 0 || !prevAnsImp) ? 'greenBack' : ''">
                        <NumberTextField 
                          v-model="data.prevStorage" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="(attribute == 0 && answer.status >= 3) || (attribute == 0 && prevAns9)"
                          :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㌧"
                        ></NumberTextField>
                      </td>
                      <td class="greenBack">
                        <NumberTextField 
                          v-model="data.thisIn" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㌧"
                        ></NumberTextField>
                      </td>
                      <td class="greenBack">
                        <NumberTextField 
                          v-model="data.thisOut" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㌧"
                        ></NumberTextField>
                      </td>
                      <td>
                        <NumberTextField 
                          v-model="data.thisStorage" 
                          dense
                          readonly 
                          :loading="loading"
                          suffix="㌧"
                          :rules="[minValLocaleJP(0)]"
                        ></NumberTextField>
                      </td>
                      <td>
                        <v-text-field 
                          v-model="data.remarks"
                          dense
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[]"
                          :maxlength="100"
                        ></v-text-field>
                      </td>
                    </tr>
                    <!-- 合計 -->
                    <tr>
                      <th colspan="2" class="sumCell">合計</th>
                      <td><NumberTextField v-model="sumAnswer9.prevStorage" dense hide-details readonly :loading="loading" suffix="㌧"></NumberTextField></td>
                      <td><NumberTextField v-model="sumAnswer9.thisIn" dense hide-details readonly :loading="loading" suffix="㌧"></NumberTextField></td>
                      <td><NumberTextField v-model="sumAnswer9.thisOut" dense hide-details readonly :loading="loading" suffix="㌧"></NumberTextField></td>
                      <td><NumberTextField v-model="sumAnswer9.thisStorage" dense hide-details readonly :loading="loading" suffix="㌧"></NumberTextField></td>
                      <td><v-text-field v-model="sumAnswer9.remarks" dense hide-details :loading="loading" :readonly="attribute == 0 && answer.status >= 3" :maxlength="100"></v-text-field></td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>

              <v-row class="justify-end">
                <v-col cols="4" sm="4" md="4" lg="4" class="pt-0">
                  <v-text-field prefix="担当者名：" v-model="answer.responderName9" readonly dense :loading="loading"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12">
                  <div>(注意)</div>
                  <ul>
                    <li>1.営業所ごとに作成すること。</li>
                    <li>2.受寄物の減失、損傷等は出庫として記載し、その旨を「備考」の欄に付記すること。</li>
                  </ul>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>

          <!-- 輸入物資 -->
          <v-tab-item value="tab-imported" class="tab-item" :transition="false" eager>
            <v-form ref="formImported" v-model="validImported" lazy-validation>
              <v-row>
                <v-col>
                  <h2>輸入物資月間入出庫高及び月末保管残高報告書</h2>
                </v-col>
              </v-row>

              <v-row class="justify-space-between">
                <v-col cols="2" sm="2" md="2" lg="2" class="pb-0">
                  <DatePicker 
                    label="調査月" 
                    v-model="answer.targetMonth" 
                    format="YYYY年M月" 
                    selectType="month" 
                    suffix="末現在" 
                    single-line 
                    :clearable="false" 
                    readonly
                    propClass="centered-input"
                    :loading="loading"
                  ></DatePicker>
                </v-col>
                <v-col cols="5" sm="5" md="5" lg="5" class="pb-0">
                  <v-text-field 
                    prefix="氏名又は名称：" 
                    v-model="answer.companyName" 
                    readonly 
                    dense 
                    :loading="loading"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="justify-space-between">
                <v-col cols="2" sm="2" md="2" lg="2" class="pt-0">
                  <v-text-field 
                    :value="officeData.prefName" 
                    single-line 
                    readonly 
                    dense 
                    class="centered-input" 
                    :loading="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" sm="5" md="5" lg="5" class="pt-0">
                  <v-text-field 
                    prefix="営業所の所在地：" 
                    v-model="answer.address" 
                    dense 
                    :loading="loading"
                    :readonly="attribute == 0 && answer.status >= 3"
                    :rules="[]"
                    :maxlength="200"
                    :counter="200"
                    background-color="input"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" sm="5" md="5" lg="5" class="pt-0">
                  <v-text-field 
                    prefix="営業所の名称：" 
                    v-model="answer.officeName" 
                    readonly 
                    dense 
                    :loading="loading"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-data-table :hide-default-header="true" :hide-default-footer="true" class="no-hover-effect table-border table-imp">
                <template v-slot:body="{}">
                  <thead>
                    <tr>
                      <th colspan="2"></th>
                      <th>前月末保管残高</th>
                      <th>当月中入庫高</th>
                      <th>当月中出庫高</th>
                      <th>当月末保管残高</th>
                      <th>備考</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- 合計以外 -->
                    <tr v-for="(data, index) in answerImported" :key="index">
                      <th width="70" class="center">{{ index + 1 }}</th>
                      <th width="200">{{ getCityDataImportedItem(data.type).text }}</th>
                      <td :class="(attribute != 0 || !prevAnsImp) ? 'greenBack' : ''">
                        <NumberTextField 
                          v-model="data.prevStorage" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="(attribute == 0 && answer.status >= 3) || (attribute == 0 && prevAnsImp)"
                          :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㌧"
                        ></NumberTextField>
                      </td>
                      <td class="greenBack">
                        <NumberTextField 
                          v-model="data.thisIn" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㌧"
                        ></NumberTextField>
                      </td>
                      <td class="greenBack">
                        <NumberTextField 
                          v-model="data.thisOut" 
                          dense
                          :orgRoundStep="1" 
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[maxValLocaleJP(9999999), minValLocaleJP(0)]"
                          suffix="㌧"
                        ></NumberTextField>
                      </td>
                      <td>
                        <NumberTextField 
                          v-model="data.thisStorage" 
                          dense
                          readonly 
                          :loading="loading"
                          suffix="㌧"
                          :rules="[minValLocaleJP(0)]"
                        ></NumberTextField>
                      </td>
                      <td>
                        <v-text-field 
                          v-model="data.remarks" 
                          dense
                          :loading="loading"
                          :readonly="attribute == 0 && answer.status >= 3"
                          :rules="[]"
                          :maxlength="100"
                        ></v-text-field>
                      </td>
                    </tr>
                    <!-- 合計 -->
                    <tr>
                      <th colspan="2">合計</th>
                      <td><NumberTextField v-model="sumAnswerImported.prevStorage" dense hide-details readonly :loading="loading" suffix="㌧"></NumberTextField></td>
                      <td><NumberTextField v-model="sumAnswerImported.thisIn" dense hide-details readonly :loading="loading" suffix="㌧"></NumberTextField></td>
                      <td><NumberTextField v-model="sumAnswerImported.thisOut" dense hide-details readonly :loading="loading" suffix="㌧"></NumberTextField></td>
                      <td><NumberTextField v-model="sumAnswerImported.thisStorage" dense hide-details readonly :loading="loading" suffix="㌧"></NumberTextField></td>
                      <td><v-text-field v-model="sumAnswerImported.remarks" dense hide-details :loading="loading" :readonly="attribute == 0 && answer.status >= 3" :maxlength="100"></v-text-field></td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>

              <v-row class="justify-end">
                <v-col cols="4" sm="4" md="4" lg="4" class="pt-0">
                  <v-text-field prefix="担当者名：" v-model="answer.responderNameImported" hide-details readonly dense :loading="loading"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row class="justify-space-between">
      <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
        <v-btn class="btn_font primary" width="120" v-on:click="saveData()" :disabled="loading || (attribute == 0 && answer.status >= 3)" >一時保存</v-btn>
      </v-col>
      <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
        <v-btn class="btn_font primary" width="120" v-on:click="answerData()" :disabled="loading || (attribute == 0 && answer.status >= 3)" >回答</v-btn>
      </v-col>
      <v-col v-if="attribute != 0 && answer.status >= 3" cols="3" sm="3" md="3" lg="3" class="text-center">
        <v-btn class="btn_font primary" width="120" v-on:click="sendBackData()" :disabled="loading" >差戻し</v-btn>
      </v-col>
      <v-col cols="3" sm="3" md="3" lg="3" class="text-center">
        <v-btn class="btn_font primary" width="120" v-on:click="cancel()" :disabled="loading">キャンセル</v-btn>
      </v-col>
    </v-row>

    <!-- modal -->
    <modal name="import-modal-dialog" :draggable="false" :clickToClose="true" :scrollable="true" 
                                height="200px" width="80%" id="vm--modal-import">
      <div class="modal-wrap">
        <div class="d-flex modal-header mt-8 pb-8 justify-space-between">
          <h2>回答票インポート</h2>
          <v-icon x-large v-on:click="hideImport()" :disabled="loading">mdi-close-circle</v-icon>
        </div>
        <div class="modal-body">
          <v-container>
            <v-row class="">
              <v-col cols="12">
                回答票Excelを選択してください。
              </v-col>
            </v-row>
            <v-row class="justify-space-between">
              <v-col cols="8">
                <v-file-input 
                  label="回答票Excelファイル" 
                  v-model="excelFile" 
                  dense 
                  accept=".xlsx"
                  truncate-length="30"
                  prepend-icon="mdi-import"
                ></v-file-input>
              </v-col>
              <v-col cols="4" class="d-flex justify-end">
                <v-btn 
                  class="btn_font primary mr-2" 
                  width="150" 
                  v-on:click="importExcel()"
                  :disabled="loading || (excelFile == null)" >
                  回答票インポート
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </modal>

  </v-container>
</template>

<script>
import Debug from "../Lib/Debug";
import Message from "../Lib/Message";
import Mixin from "../mixins/const.js";
import XlsxImport from '../mixins/excel_import';
import Util from '../Lib/Util';
import * as XLSX from "xlsx";

import DatePicker from "./common/DatePicker.vue";
import NumberTextField from "./common/Number_text_field.vue";
import AlertSnackbar from "./common/AlertSnackbar.vue";

import CityDataAnswer from "../model/cityDataAnswer.js";

export default {
  components: {
    DatePicker,
    NumberTextField,
    AlertSnackbar,
  },

  mixins: [Mixin,XlsxImport],

  data: () => ({
    validNo8: true,
    validNo9: true,
    validImported: true,

    loading: false,

    show: true,

    // ログインユーザ属性
    attribute: 0,

    // 事業所データ
    officeData: {},

    // 回答データ
    answer: {
      sid: null,
      memberNo: '',
      targetMonth: '',
      companyName: '',
      officeName: '',
      address: '',
      responderName8: '',
      responderName9: '',
      responderNameImported: '',
      status: 0,
    },
    answer8: {
      answerId: null,
      volumeTotal: 0,
      volumeReceipt: 0,
      volumeMeasure: 0,
      volumePrivate: 0,
      volumeAvailable: 0,
      weightIce: 0,
      weightFrozen: 0,
    },
    answer9: [
      {answerId: null, type: 1, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 2, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 3, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 4, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 5, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 6, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 7, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 8, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 9, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 10, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 11, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
    ],
    sumAnswer9: {},
    answerImported: [
      {answerId: null, type: 1, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 2, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 3, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 4, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 5, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 6, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 7, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 8, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 9, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 10, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 11, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 12, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
      {answerId: null, type: 13, prevStorage: 0, thisIn: 0, thisOut: 0, thisStorage: 0, remarks: ''},
    ],
    sumAnswerImported: {},

    
    surveyId: null,
    memberNo: null,
    targetMonth: null,

    //sameResponderNameAnswer9: true, // 8号様式と同じ担当者かの判定用(9号様式)
    //sameResponderNameAnswerImported: true, // 8号様式と同じ担当者かの判定用(輸入物資)

    // バリデーション用 回答フラグ
    saveRegist: false,

    // 前月回答フラグ
    prevAns9: false,
    prevAnsImp: false,

    // formバリデーション
    formNo8Valid: true,
    formNo9Valid: true,
    formImportedVald: true,

    // file
    excelFile: null,
  }),

  // ライフサイクルフック
  created: function () {
    this.attribute = this.$store.getters.getAttribute;  // 属性

    this.getData();
  },

  mounted: async function () {},

  methods: {
    // 表示更新
    async getData() {
      Debug.log("function[getData]");
      this.loading = true;
      this.surveyId = this.$store.getters.getTargetSurveyId; // 編集する調査IDをstoreから取得
      this.memberNo = this.$store.getters.getTargetMemberNo; // 編集する会員IDをstoreから取得
      this.targetMonth = this.$store.getters.getStoreSearchCondition.targetMonth // 編集対象の年月をstoreから取得
      try {
        const res = await CityDataAnswer.getData(this.surveyId, this.memberNo, this.targetMonth);
        Debug.log(res.data);

        if (res.status == 200 && res.data) {
          this.officeData = res.data.officeData;
          if(Object.keys(res.data.answer).length > 0) {
            // 回答データが存在する場合、データ格納
            this.answer = res.data.answer;
            this.answer8 = res.data.answer8;
            this.answer9 = res.data.answer9;
            this.answerImported = res.data.answerImported;
          } else {
            // 新規の場合初期値を設定
            this.answer.targetMonth = this.targetMonth + '-01';
            this.answer.memberNo = this.memberNo;
            this.answer.companyName = this.officeData.companyName;
            this.answer.officeName = this.officeData.officeName;
            this.answer.address = this.officeData.addressBldg;

            // 9号 前月末保管残高
            if(res.data.prevAnswer9.length > 0) {
              for(let i = 0 ; i < this.answer9.length ; i++) {
                // 前月回答データの当月末保管残高を設定する
                const tmpItem = res.data.prevAnswer9.find((v) => v.type == this.answer9[i].type);
                this.$set(this.answer9[i], 'prevStorage', tmpItem.thisStorage); 
              }
            }

            // 輸入物資 前月末保管残高
            if(res.data.prevAnswerImported.length > 0) {
              for(let i = 0 ; i < this.answerImported.length ; i++) {
                // 前月回答データの当月末保管残高を設定する
                const tmpItem = res.data.prevAnswerImported.find((v) => v.type == this.answerImported[i].type);
                this.$set(this.answerImported[i], 'prevStorage', tmpItem.thisStorage); 
              }
            }
          }

          if(res.data.prevAnswer9.length > 0) {
            // 前月9号回答あり
            this.prevAns9 = true;
          }
          if(res.data.prevAnswerImported.length > 0) {
            // 前月輸入物資回答あり
            this.prevAnsImp = true;
          }
        }
      } catch (error) {
        Message.err(error, "月次報告データを取得できませんでした");
      }

      // 合計用の最後の配列を切り離す
      this.sumAnswer9 = this.answer9.pop();
      this.sumAnswerImported = this.answerImported.pop();

      // 回答済データが8号様式と同じ担当者かの判定
      // if (this.answer8.responder_name != this.answer9[0].responder_name) {
      //   this.sameResponderNameAnswer9 = false;
      // }
      // if (this.answer8.responder_name != this.answerImported[0].responder_name) {
      //   this.sameResponderNameAnswerImported = false;
      // }

      this.loading = false;
    },

    /** 一時保存 */
    async saveData() {
      Debug.log("function[saveData]");

      this.formNo8Valid = this.$refs.formNo8.validate();
      this.formNo9Valid = this.$refs.formNo9.validate();
      this.formImportedVald = this.$refs.formImported.validate();

      if (!this.formNo8Valid ||
          !this.formNo9Valid ||
          !this.formImportedVald) {
        let msg = [];
        if(!this.formNo8Valid) msg.push("8号様式");
        if(!this.formNo9Valid) msg.push("9号様式");
        if(!this.formImportedVald) msg.push("輸入物資");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("一時保存します。よろしいですか？")) return;
      this.answer.status = 1; // statusを一時保存中に変更
      this.updateValue(); // computedの自動計算値を更新
      this.registData(); // DBに登録
    },
    /** 回答 */
    async answerData() {
      Debug.log("function[answerData]");
      this.saveRegist = true;

      this.formNo8Valid = this.$refs.formNo8.validate();
      this.formNo9Valid = this.$refs.formNo9.validate();
      this.formImportedVald = this.$refs.formImported.validate();

      if (!this.formNo8Valid ||
          !this.formNo9Valid ||
          !this.formImportedVald) {
        let msg = [];
        if(!this.formNo8Valid) msg.push("8号様式");
        if(!this.formNo9Valid) msg.push("9号様式");
        if(!this.formImportedVald) msg.push("輸入物資");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        this.saveRegist = false;
        return;
      }

      if (!confirm("回答を送信します。送信後は回答を変更できません。よろしいですか？")) return;
      this.answer.status = 3; // statusを送信済に変更
      this.updateValue(); // computedの自動計算値を更新

      this.registData(); // DBに登録
    },
    // 差戻し
    async sendBackData() {
      this.formNo8Valid = this.$refs.formNo8.validate();
      this.formNo9Valid = this.$refs.formNo9.validate();
      this.formImportedVald = this.$refs.formImported.validate();

      if (!this.formNo8Valid ||
          !this.formNo9Valid ||
          !this.formImportedVald) {
        let msg = [];
        if(!this.formNo8Valid) msg.push("8号様式");
        if(!this.formNo9Valid) msg.push("9号様式");
        if(!this.formImportedVald) msg.push("輸入物資");

        alert(msg.join("、")+"に誤りがあります。内容をご確認ください。");
        return;
      }

      if (!confirm("回答を保存して差戻します。差戻し後は事業所が回答を変更できるようになります。よろしいですか？")) return;

      this.answer.status = 1; // statusを一時保存中に変更
      this.registData(true);
    },
    /** 取消 */
    cancel() {
      Debug.log("function[cancel]");

      if (!confirm("キャンセルします。よろしいですか？")) return;
      this.$router.back();
    },

    /** DBに登録 */
    async registData(sendBack=false) {
      this.loading = true;
      try {
        const res = await CityDataAnswer.setData(this.answer, this.answer8, this.answer9, this.sumAnswer9, this.answerImported, this.sumAnswerImported);

        // サーバーサイドのバリデーション
        const validationMsg = res.data;
        if (validationMsg) {
          let message = "";

          if (Array.isArray(validationMsg)) {
            validationMsg.forEach((m) => (message += m + "\n"));
          } else {
            message = validationMsg;
          }

          alert(message);
          this.$router.back();
          this.loading = false;
          return;
        } else {
          alert(this.answer.status == 1 && sendBack ? "確定を解除しました。" :
                  this.answer.status == 1 ? "一時保存しました。" : "送信しました。");
          this.$router.back();
          this.loading = false;
        }
      } catch (error) {
        Message.err(error, this.answer.status == 1 && sendBack ? "確定を解除できませんでした。" :
                            this.answer.status == 1 ? "一時保存できませんでした。" : "送信できませんでした。");
        this.loading = false;
      }
    },

    updateValue() {
      // computedの自動計算値を更新
      this.answer8.volumeReceipt = this.answer8VolumeReceiptCalc;
      this.answer8.volumeAvailable = this.answer8VolumeAvailableCalc;

      // 担当者のチェック状態反映
      // if (this.sameResponderNameAnswer9 === true) {
      //   this.answer9[0].responder_name = this.answer8.responder_name;
      // }
      // if (this.sameResponderNameAnswerImported === true) {
      //   this.answerImported[0].responder_name = this.answer8.responder_name;
      // }
    },

    /** 回答票Excelインポート */
    showImport () {
      Debug.log('function[showImport]');

      this.$modal.show('import-modal-dialog');
    },
    hideImport : function () {
      Debug.log('function[hideImport]');
      this.$modal.hide('import-modal-dialog');
    },

    getExt(fileName) {
      var pos = fileName.lastIndexOf('.');
      if (pos === -1) return '';
      return fileName.slice(pos + 1);
    },

    importExcel() {
      const e = this.excelFile;
      Debug.log2('file', e);
      Debug.log2('ext', this.getExt(e.name));
      if(this.getExt(e.name) !== 'xlsx'){
        alert("xlsx形式のファイルを指定してください。");
        return;
      }

      if (!confirm("回答票Excelの入力値をコピーします。よろしいですか？")) return;

      var reader = new FileReader();
      reader.onload = function (e) {
        var unit8 = new Uint8Array(e.target.result);

        const workbook = XLSX.read(unit8, {type: "array"});
        const sheetNames = workbook.SheetNames;
        let sheetName = sheetNames[0];
        let sheet = workbook.Sheets[sheetName];
        let rows = XLSX.utils.sheet_to_json(sheet);

        Debug.log2('rows', rows);

        // ===== 8号様式 データコピー =====
        // 基礎情報
        this.$set(this.answer, "companyName", this.getCellValue(sheet, 'G4', 'v'));           // 氏名又は名称
        this.$set(this.answer, "officeName", this.getCellValue(sheet, 'G6', 'v'));            // 営業所の名称
        this.$set(this.answer, "responderName8", this.getCellValue(sheet, 'J21', 'w'));       // 担当者名
        // 8号
        this.$set(this.answer8, "volumeTotal", Util.orgRound(this.getCellValue(sheet, 'C11', 'v', true), 1));     // 所管容積
        this.$set(this.answer8, "volumeMeasure", Util.orgRound(this.getCellValue(sheet, 'F17', 'v', true), 1));   // 内容積建
        this.$set(this.answer8, "volumePrivate", Util.orgRound(this.getCellValue(sheet, 'G11', 'v', true), 1));   // 自家貨物在貨容積
        this.$set(this.answer8, "weightIce", Util.orgRound(this.getCellValue(sheet, 'I13', 'v', true), 1));       // 製氷
        this.$set(this.answer8, "weightFrozen", Util.orgRound(this.getCellValue(sheet, 'J13', 'v', true), 1));    // 凍結

        // ===== 9号様式 データコピー =====
        if(sheetNames.length >= 2) {
          let sheetName = sheetNames[1];
          let sheet = workbook.Sheets[sheetName];
          let rows = XLSX.utils.sheet_to_json(sheet);

          Debug.log2('rows', rows);

          let row = 10;
          for(let i=0; i<this.answer9.length; i++) {
            let col = 5;

            if(((this.attribute == 0) && !this.prevAns9) ||
                (this.attribute != 0)) {
              this.$set(this.answer9[i], 'prevStorage', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                            1));    // 前月末保管残高
            } else {
              col++;
            }
            this.$set(this.answer9[i], 'thisIn', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                          1));    // 当月中入庫高
            this.$set(this.answer9[i], 'thisOut', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                          1));    // 当月中出庫高
            col++;
            this.$set(this.answer9[i], 'remarks', this.getCellValue(sheet, this.getEncode_cell(col++, row), 'w'));      // 備考

            row++;
          }
          this.$set(this.sumAnswer9, 'remarks', this.getCellValue(sheet, 'J21', 'w'));      // 合計備考
        }

        // ===== 輸入物資 データコピー =====
        if(sheetNames.length >= 3) {
          let sheetName = sheetNames[2];
          let sheet = workbook.Sheets[sheetName];
          let rows = XLSX.utils.sheet_to_json(sheet);

          Debug.log2('rows', rows);

          this.$set(this.answer, 'address', this.getCellValue(sheet, 'G6', 'w'));      // 営業所の所在地

          let row = 9;
          for(let i=0; i<this.answerImported.length; i++) {
            let col = 5;

            if(((this.attribute == 0) && !this.prevAnsImp) ||
                (this.attribute != 0)) {
              this.$set(this.answerImported[i], 'prevStorage', Util.orgRound(
                                                            this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                            1));    // 前月末保管残高
            } else {
              col++;
            }
            this.$set(this.answerImported[i], 'thisIn', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                          1));    // 当月中入庫高
            this.$set(this.answerImported[i], 'thisOut', Util.orgRound(
                                                          this.getCellValue(sheet, this.getEncode_cell(col++, row), 'v', true),
                                                          1));    // 当月中出庫高
            col++;
            this.$set(this.answerImported[i], 'remarks', this.getCellValue(sheet, this.getEncode_cell(col++, row), 'w'));      // 備考

            row++;
          }
          this.$set(this.sumAnswerImported, 'remarks', this.getCellValue(sheet, 'J22', 'w'));      // 合計備考
        }

      }.bind(this);
      reader.readAsArrayBuffer(e);

      this.hideImport();
    },

  },

  computed: {
    // 八号様式
    answer8VolumeReceiptCalc() {
      // 受奇物在貨容積の算出
      const volumeReceipt = this.sumAnswer9.thisStorage * 2.5 + this.answer8.volumeMeasure;
      if(isNaN(volumeReceipt)) return 0;
      return Util.orgRound(volumeReceipt, 1);
    },

    answer8VolumeAvailableCalc() {
      // 空容積の算出
      const volumeAvailable = this.answer8.volumeTotal - (this.answer8VolumeReceiptCalc + this.answer8.volumePrivate);
      if(isNaN(volumeAvailable)) return 0;
      return volumeAvailable;
    },
  },

  watch: {
    // 8号 担当者名
    'answer.responderName8': function(newValue){
      // 9号 担当者名を設定
      this.answer.responderName9 = newValue;
      // 輸入物資 担当者名を設定
      this.answer.responderNameImported = newValue;
    },

    // 九号様式
    answer9: {
      handler: function () {
        let sum = this.sumAnswer9;
        // 合計の初期化
        sum.prevStorage = 0;
        sum.thisIn = 0;
        sum.thisOut = 0;
        sum.thisStorage = 0;

        this.answer9.forEach(function (itemData) {
          // 当月末保管残高の算出
          itemData.thisStorage = itemData.prevStorage + itemData.thisIn - itemData.thisOut;

          // 合計の算出
          sum.prevStorage += itemData.prevStorage; // 前月末保管残高
          sum.thisIn += itemData.thisIn; // 当月中入庫高
          sum.thisOut += itemData.thisOut; // 当月中出庫高
          sum.thisStorage += itemData.thisStorage; // 当月末保管残高
        });
      },
      deep: true,
    },

    // 輸入物資
    answerImported: {
      handler: function () {
        let sum = this.sumAnswerImported;
        // 合計の初期化
        sum.prevStorage = 0;
        sum.thisIn = 0;
        sum.thisOut = 0;
        sum.thisStorage = 0;

        this.answerImported.forEach(function (itemData) {
          // 当月末保管残高の算出
          itemData.thisStorage = itemData.prevStorage + itemData.thisIn - itemData.thisOut;

          // 合計の算出
          sum.prevStorage += itemData.prevStorage; // 前月末保管残高
          sum.thisIn += itemData.thisIn; // 当月中入庫高
          sum.thisOut += itemData.thisOut; // 当月中出庫高
          sum.thisStorage += itemData.thisStorage; // 当月末保管残高
        });
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.greenBack {
  background-color: var(--v-input-base);
}
.v-data-table >>> table {
  table-layout: auto;
  border-collapse: collapse;
}
.v-data-table >>> table > thead > tr > th,
.v-data-table >>> table > tbody > tr > th,
.v-data-table >>> table > tbody > tr > td {
  white-space: pre-wrap;
  border-bottom: 1px solid #888888 !important;
}
.selected {
  background-color: var(--v-dimgray-base);
  color: #ffffff;
}

.v-data-table + .row,
.row + .v-data-table {
  margin-top: 12px;
}
#tab-no8 tbody {
  height: 200px;
}
ul {
  list-style: none;
}

/** タブ */
.v-slide-group__wrapper {
  height: 25px !important;
}
.v-tabs >>> .v-tabs-bar__content {
  height: 25px !important;
}
.v-tabs >>> .v-tabs-bar {
  height: 25px !important;
}
.v-tabs >>> .v-slide-group__wrapper {
  border-bottom: 1px solid var(--v-primary-base);
}

.v-tabs >>> .v-tabs-bar__content .v-tab {
  border-top: 1px solid var(--v-primary-base);
  border-right: 1px solid var(--v-primary-base);
  border-bottom: 1px solid var(--v-primary-base);
}
.v-tabs >>> .v-tabs-bar__content .v-tab:first-of-type {
  border-left: 1px solid var(--v-primary-base);
}

.v-tab {
  height: 25px !important;
  font-weight: bold;
}
.v-tab:hover {
  background-color: var(--v-info_hover-base) !important;
}
.v-tab--active {
  background-color: var(--v-info-base);
}
.v-tab--active:hover {
  background-color: var(--v-info-base);
}



.tab-width-200 {
  width: 200px;
  min-width: 200px;
}

.tab-item {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 220px);
}

/** 8号テーブル */
.v-data-table.table-8 >>> table > th, td {
  max-width: 100px;
}
.v-data-table.table-8 >>> .v-data-table__wrapper thead th {
  height: 30px;
}
.v-data-table.table-8 >>> .v-data-table__wrapper tr > th:first-child {
  width: 100px;
  max-width: 100px;
}

/** 9号テーブル */
.v-data-table.table-9 >>> .v-data-table__wrapper thead th {
  height: 30px;
}
.v-data-table.table-9 >>> .v-data-table__wrapper thead > tr > th:first-child {
  width: 150px;
  max-width: 150px;
}
.v-data-table.table-9 >>> .v-data-table__wrapper tbody > tr > th:first-child {
  width: 50px;
  max-width: 50px;
}
.v-data-table.table-9 >>> .v-data-table__wrapper tbody > tr > th:nth-of-type(2) {
  width: 100px;
  max-width: 100px;
}

/** 輸入物資テーブル */
.v-data-table.table-imp >>> .v-data-table__wrapper thead th {
  height: 30px;
}
.v-data-table.table-imp >>> .v-data-table__wrapper thead > tr > th:first-child {
  width: 200px;
  max-width: 200px;
}
.v-data-table.table-imp >>> .v-data-table__wrapper tbody > tr > th:first-child {
  width: 50px;
  max-width: 50px;
}
.v-data-table.table-imp >>> .v-data-table__wrapper tbody > tr > th:nth-of-type(2) {
  width: 150px;
  max-width: 150px;
  word-break: keep-all;
}

/** モーダル */
.modal-wrap {
  height: 100%;
}
.modal-header {
  height: 10%;
}
.modal-header h2 {
  align-items: center;
  display: inline-flex;
}
.modal-body {
  overflow-y: scroll;
  height: 90%;
}
</style>
